<script setup>
import { toRefs } from '#imports';

const props = defineProps(['modelValue']);
const { modelValue } = toRefs(props);
const emit = defineEmits(['update:modelValue']);
</script>
<template>
  <div>
    <span class="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        :class="
          modelValue === 'grid'
            ? 'bg-neutral-100 text-neutral-800 dark:bg-neutral-800 dark:text-neutral-100'
            : 'bg-white text-neutral-500 dark:bg-black dark:text-neutral-400'
        "
        class="relative inline-flex items-center rounded-l-md border border-neutral-300 px-2 py-2 text-sm font-medium hover:bg-neutral-100 hover:text-neutral-800 dark:border-neutral-600 dark:text-neutral-100 dark:hover:bg-neutral-800"
        @click="emit('update:modelValue', 'grid')"
      >
        <span class="sr-only">Large</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-5 w-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
          />
        </svg>
      </button>
      <button
        type="button"
        :class="
          modelValue === 'grid-compact'
            ? 'bg-neutral-100 text-neutral-800 dark:bg-neutral-800 dark:text-neutral-100'
            : 'bg-white text-neutral-500 dark:bg-black dark:text-neutral-400'
        "
        class="relative -ml-px inline-flex items-center rounded-r-md border border-neutral-300 px-2 py-2 text-sm font-medium hover:bg-neutral-100 hover:text-neutral-800 dark:border-neutral-600 dark:text-neutral-100 dark:hover:bg-neutral-800"
        @click="emit('update:modelValue', 'grid-compact')"
      >
        <span class="sr-only">Compact</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-5 w-5"
        >
          <g transform="matrix(0.636364,0,0,0.636364,1.23864,1.23864)">
            <path
              d="M3.75,6C3.75,4.766 4.766,3.75 6,3.75L8.25,3.75C9.484,3.75 10.5,4.766 10.5,6L10.5,8.25C10.5,9.484 9.484,10.5 8.25,10.5L6,10.5C4.766,10.5 3.75,9.484 3.75,8.25L3.75,6ZM3.75,15.75C3.75,14.516 4.766,13.5 6,13.5L8.25,13.5C9.484,13.5 10.5,14.516 10.5,15.75L10.5,18C10.5,19.234 9.484,20.25 8.25,20.25L6,20.25C4.766,20.25 3.75,19.234 3.75,18L3.75,15.75ZM13.5,6C13.5,4.766 14.516,3.75 15.75,3.75L18,3.75C19.234,3.75 20.25,4.766 20.25,6L20.25,8.25C20.25,9.484 19.234,10.5 18,10.5L15.75,10.5C14.516,10.5 13.5,9.484 13.5,8.25L13.5,6ZM13.5,15.75C13.5,14.516 14.516,13.5 15.75,13.5L18,13.5C19.234,13.5 20.25,14.516 20.25,15.75L20.25,18C20.25,19.234 19.234,20.25 18,20.25L15.75,20.25C14.516,20.25 13.5,19.234 13.5,18L13.5,15.75Z"
              style="fill: none; fill-rule: nonzero; stroke-width: 1.5px"
            />
          </g>
          <g transform="matrix(0.636364,0,0,0.636364,7.48864,1.23864)">
            <path
              d="M13.5,6C13.5,4.766 14.516,3.75 15.75,3.75L18,3.75C19.234,3.75 20.25,4.766 20.25,6L20.25,8.25C20.25,9.484 19.234,10.5 18,10.5L15.75,10.5C14.516,10.5 13.5,9.484 13.5,8.25L13.5,6ZM13.5,15.75C13.5,14.516 14.516,13.5 15.75,13.5L18,13.5C19.234,13.5 20.25,14.516 20.25,15.75L20.25,18C20.25,19.234 19.234,20.25 18,20.25L15.75,20.25C14.516,20.25 13.5,19.234 13.5,18L13.5,15.75Z"
              style="fill: none; fill-rule: nonzero; stroke-width: 1.5px"
            />
          </g>
          <g transform="matrix(0.636364,0,0,0.636364,7.48864,7.48864)">
            <path
              d="M13.5,15.75C13.5,14.516 14.516,13.5 15.75,13.5L18,13.5C19.234,13.5 20.25,14.516 20.25,15.75L20.25,18C20.25,19.234 19.234,20.25 18,20.25L15.75,20.25C14.516,20.25 13.5,19.234 13.5,18L13.5,15.75Z"
              style="fill: none; fill-rule: nonzero; stroke-width: 1.5px"
            />
          </g>
          <g transform="matrix(0.636364,0,0,0.636364,1.23864,7.48864)">
            <path
              d="M3.75,15.75C3.75,14.516 4.766,13.5 6,13.5L8.25,13.5C9.484,13.5 10.5,14.516 10.5,15.75L10.5,18C10.5,19.234 9.484,20.25 8.25,20.25L6,20.25C4.766,20.25 3.75,19.234 3.75,18L3.75,15.75ZM13.5,15.75C13.5,14.516 14.516,13.5 15.75,13.5L18,13.5C19.234,13.5 20.25,14.516 20.25,15.75L20.25,18C20.25,19.234 19.234,20.25 18,20.25L15.75,20.25C14.516,20.25 13.5,19.234 13.5,18L13.5,15.75Z"
              style="fill: none; fill-rule: nonzero; stroke-width: 1.5px"
            />
          </g>
        </svg>
      </button>
    </span>
  </div>
</template>
